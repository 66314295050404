.project {
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 50vh;
  padding: 20px;

  border-radius: 15px;

  cursor: pointer;
}

.project h3 {
  font-size: 20px;
}

.project video {
  width: 70%;
}

.project p {
  text-align: justify;
  width: auto;
  width: 80% !important;
}

.project a {
  text-decoration: none;
  color: #19a7ce;
}

.shown {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  animation: fade 1s ease-in-out;
}

.hidden {
  display: none;
}

@keyframes fade {
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
  }
}

.color1 {
  box-shadow: 0px 0px 35px rgb(2, 222, 238);
}

.color2 {
  box-shadow: 0px 0px 35px rgb(19, 224, 88);
}

.color0 {
  box-shadow: 0px 0px 35px rgb(12, 243, 193);
}
