.home{
    width: 100vw;
    height: 90vh;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.home-texts{
    width: 40%;
    height: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-radius: 15px;


}

.texts-img {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;    
    width: 300px;

}

.texts-img img {
    width: 50px;
}

.home-texts h1{
    text-transform: uppercase;
    font-size: 45px;
    width: 500px;
    text-align: center;
}

.home-texts h1 i{
    color: #19A7CE;
    font-style: normal;
}

.home-img{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.home-img img{
    width: 450px;
    border-radius: 50%;
}

.home-texts p{
    text-align: center;
    width: 60%;
}


@media (max-width: 1200px) {
    .home{
        flex-direction: column;
    }

    .home-texts{
        height: 35%;
    }

    .home-texts p {
        width: 90%;
    }
}