.Header{
    width: 100vw;
    height: 10vh;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    position: sticky;
    z-index: 100;
    top:0;

/*     box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */

    font-family: 'Poppins';

    background-color: rgba(0, 0, 0, 0.75);

}

.Header h1{
    text-transform: uppercase;
    margin-left: 15px;
}


.header-links{
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
}

.header-links ul{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.header-links ul li {
    list-style: none;
}

.header-links ul li a{
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    transition: 0.3s;
}


.header-links ul li a:hover{
    color:lightblue;
}


@media (max-width: 1200px) {
    .Header{
        display: none;
    }
}