.skills{
    width: 100vw;
    height: 90vh;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}


.skills-images{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    flex-wrap: wrap;

    width: 40vw;
    height: 80vh;

    background-color: rgba(255, 255, 255,0.65);
    border-radius: 15px;
    box-shadow: 0px 0px 25px white;
}



.skills-images img{
    width: 200px;
}